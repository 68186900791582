import { ApplicationCapabilityCategories } from "~/classes/models/capabilities/application-capability-category.model";
import { CapabilitiesService } from "~/classes/models/capabilities/capabilities.service";
import { Subscription } from "rxjs";

export const useApplicationCapabilityCategories = definePiniaStore(
  "/application/capability-categories",
  () => {
    const applicationCapabilityCategories =
      ref<ApplicationCapabilityCategories>([]);

    const subscription = ref<Subscription | null>(null);

    const isInitialized = ref(false);
    const initialize = () => {
      if (isInitialized.value == true) {
        return;
      }

      subscription.value?.unsubscribe();
      subscription.value =
        CapabilitiesService.streamApplicationCapabilityCategories().subscribe(
          (categories) => {
            applicationCapabilityCategories.value = categories;
          }
        );
      isInitialized.value = true;
    };

    return { initialize, applicationCapabilityCategories };
  }
);
